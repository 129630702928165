<template>
  <div class="card text-vue" style="width: 602px">
    <div class="card-header">
      <h2 class="bg-vue2 datenschutz">Datenschutz</h2>
    </div>
    <div class="card-body scroll">
      <p>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. In Verbindung mit Ihrem
        Zugriff werden in unserem Server für Zwecke der Datensicherheit
        vorübergehend Daten gespeichert, die möglicherweise eine Identifizierung
        zulassen (zum Beispiel IP-Adresse, Datum, Uhrzeit und Name der
        angeforderten Datei). Eine Auswertung, mit Ausnahme für statistische
        Zwecke in anonymisierter Form, erfolgt nicht. Sollten Sie bei uns
        Informationsmaterialien bestellen, benötigen wir von Ihnen Name,
        E-Mail-Adresse sowie Angaben zur Lieferung und Rechnungsstellung. So
        können wir Ihre Bestellung bearbeiten und ausführen. Diese Daten werden
        für die Dauer der Auftragsabwicklung in elektronischer Form gespeichert.
        Eine weitergehende Nutzung personenbezogener Daten oder eine Weitergabe
        an Dritte erfolgt ohne Ihre ausdrückliche Einwilligung nicht. Mit der
        Bestellung willigen Sie in die genannte Verarbeitung Ihrer
        personenbezogenen Daten ein, soweit dies für den Zweck des Vertrages
        erforderlich ist. Sie können diese Einwilligung schriftlich oder per
        E-Mail uns gegenüber jederzeit mit Wirkung für die Zukunft widerrufen.
        Quelle: <a href="www.disclaimer.de">www.disclaimer.de</a>
      </p>
      <h5>Urheberrecht</h5>
      <p>
        Die durch den Betreiber dieser Seite erstellten Inhalte und Werke auf
        diesen Webseiten unterliegen dem deutschen Urheberrecht. Sämtliche
        Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Kopien von diesen Seiten sind nur für
        den privaten Bereich gestattet, nicht jedoch für kommerzielle Zwecke.
      </p>
      <h5>Haftung für Links</h5>
      <p>
        Wir sind für den Inhalt von Webseiten, die über einen Hyperlink erreicht
        werden, nicht verantwortlich. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich. Wir machen uns die
        Inhalte dieser Internetseiten ausdrücklich nicht zu eigen und können
        deshalb für die inhaltliche Korrektheit, Vollständigkeit und
        Verfügbarkeit keine Gewähr leisten. Wir haben bei der erstmaligen
        Verknüpfung zwar den fremden Inhalt daraufhin überprüft, ob durch ihn
        eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit
        ausgelöst wird. Wir sind aber nicht dazu verpflichtet, die Inhalte, auf
        die wir unserem Angebot verweisen, ständig auf Veränderungen zu
        überprüfen, die eine Verantwortlichkeit neu begründen könnten. Erst wenn
        wir feststellen oder von anderen darauf hingewiesen werden, dass ein
        konkretes Angebot, zu dem wir einen Link bereitgestellt haben, eine
        zivil- oder strafrechtliche Verantwortlichkeit auslöst, werden wir den
        Verweis auf dieses Angebot aufheben, soweit uns dies technisch möglich
        und zumutbar ist.
      </p>
      <h5>Haftung für Inhalte</h5>
      <p>
        Die auf den Web-Seiten abrufbaren Beiträge dienen nur der allgemeinen
        Information und nicht der Beratung in konkreten Fällen. Wir sind bemüht,
        für die Richtigkeit und Aktualität aller auf der Website enthaltenen
        Informationen und Daten gemäß § 7 Abs.1 TMG zu sorgen. Für die
        Korrektheit, Vollständigkeit, Aktualität oder Qualität der
        bereitgestellten Informationen und Daten wird jedoch keine Gewähr nach
        §§ 8 bis 10 TMG übernommen. Die Haftung für den Inhalt der abrufbaren
        Informationen wird ausgeschlossen, soweit es sich nicht um vorsätzliche
        oder grob fahrlässige Falschinformation handelt. Verpflichtungen zur
        Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h5>Webdesign</h5>
      <p><a href="www.als-werbung.de">www.als-werbung.de</a></p>
      <h5>Haftung für Inhalte</h5>
      <p>David Weigelt</p>
      <button type="button" class="btn btn-danger" @click="close()">
        Close
      </button>
      
      <h5>Copyright Bilder</h5>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_12416146 by Wolfilser</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_32895389 by Birgit Reitz-Hofmann</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_44563273 by DOC RABE Media</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_76816016 by akf</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_85781068 by Mariusz Blach</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_114122893 by Dariusz Jarzabek</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_127879174 by Kurhan</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_132028782 by Daniel Strautmann</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_191319062 by alphaspirit</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_192584899 by abasler</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_217496158 by Wellnhofer Designs</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_224037395 by korisbo</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_288346827 by REDPIXEL</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_292188498 by Hermann</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_295272383 by Image'in</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_352638534 by Евгений Вершинин</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_384386971 by Tricky Shark</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_455456849 by Oksana</p>
      <p class="lh-1 m-0 fw-lighter">AdobeStock_459040036 by Blue Planet Studio</p>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer">
      
    </div>
  </div>
</template>
<script>
  import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalDatenschutz",
  methods:{
  close() {
      closeModal();
    },
    },
};
</script>
