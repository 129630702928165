<template>
  <carousel :items-to-show="1.5" :autoplay="3000" :wrap-around="true">
    <slide v-for="slide in slides" :key="slide"><img
        class="img-fluid"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"
          
          v-bind:class="imgCH " />        
      
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      {bild:"Grau01",text:""},
      {bild:"Grau02",text:""},
      {bild:"Grau03",text:""},
      {bild:"Grau04",text:""},
      {bild:"Grau05",text:""},
      {bild:"Grau06",text:""},
      {bild:"Grau07",text:""},
      
    ];
    return {
      slides,
    };
  },
};
</script>
