<template>
  <div>    
    <main>
      <div class="container-fluid m-0 p-0 ">
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in"
              appear
              :key="$route.path"
              ><slot v-if="true"><h1>PerfektBau</h1></slot></transition
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
//import Footer from "@/components/Footer";

export default {
  name: "Layout",
  components: {
   // Footer,
  },
};
</script>

<style scoped>
</style>