<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0">
          <div class="row ">
            <div class="col-12 p-0">
              <img src="../assets/Logo.png" class="logoObenLinks" alt="..." />
              <img src="../assets/panorama5.jpeg" class="img-fluid" alt="..." />
              

            </div>
          </div>
          <div class="row justify-content-center nav">
            <div class="col-md-auto">
              <nav class="navbar navbar-expand-lg nav">
                <div class="container-fluid">
                  <ul class="navbar-nav me-auto  hemihead">
                    <li class="nav-item active hemihead">
                      <router-link 
                      to="/" 
                      class="nav-link nav "
                       v-bind:class="[activeScreen == '/' ? 'nav-active' : 'nav-deactiv']"
                        >Startseite</router-link >
                    </li>
                    <li class="nav-item dropdown  hemihead">
                      <router-link
                        to="/Winterdienst"
                        @mouseover="toggleDdown()"
                        @click="toggleDdown()"
                        class="nav-link nav-active"                         
                        >Entsorgung</router-link
                      >

                      <ul class="ddown-menu keinHandy" v-if="visi == true">
                        
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Winterdienst"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>                        
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>                                  
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Entsorgung"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Entsorgung</router-link
                          >
                        </li>                     
                        <li class="ddownLi mb-4">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>
                      </ul>
                    </li>


<li class="ddownLi  handyOnly">
                          <router-link
                            to="/Winterdienst"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Winterdienst</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>
                        <li class="ddownLi handyOnly">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>


                   <li class="nav-item nav">
                      <router-link to="/Auftragsformular" class="nav-link nav"
                       v-bind:class="[activeScreen == '/Auftragsformular' ? 'nav-active' : 'nav-deactiv']"
                        >Auftragsformular</router-link
                      > 
                    </li>
                    <li class="nav-item nav">
                      <router-link to="/Kontakt" class="nav-link nav"
                       v-bind:class="[activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv']"
                        >Kontakt</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div class="row unten">
            <div class="col-md-3 ps-5 pt-3">
              <img src="../assets/entsorgung1.jpg" class="img-fluid " alt="..." />
            </div>
            <div class="col-md-3 ps-5 pt-3">
              <img src="../assets/entsorgung2.jpg" class="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 ps-5 pt-3">
              <h3>Entsorgung</h3>
              <ul>
                <li>Sperrmüll</li>                
                <li>Laub und Grünschnitt</li>
                <li>Wohnungsauflösungen</li>
                <li>Sonstige Verunreinigungen</li>
              </ul>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-2 footer offset-md-10">    
              <span @click="openImpressum()">Impressum</span> &nbsp;
              <span @click="openDatenschutz()">Datenschutz</span>              
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { mapGetters } from "vuex";
import modalImpressum from "../components/Impressum.vue";
import modalDatenschutz from "../components/Datenschutz.vue";
import { openModal } from "jenesius-vue-modal";


export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      visi: false,
    };
  },
  components: {
    Layout,
  },
  methods: {
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 5000);
    },
    openImpressum(){
     openModal(modalImpressum);
    },
    openDatenschutz(){
     openModal(modalDatenschutz);
    },

    ifAktuell() {
      if (this.activeScreen == "/") {
        return true;
      } else if (this.activeScreen == "/Winterdienst") {
        return true;
      } else if (this.activeScreen == "/Auftragsformular") {
        return true;
      } else if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
};
</script>

<style scoped></style>
