<template>
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
    <slide v-for="slide in slides" :key="slide"><img
        class="img-fluid"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"
          
          v-bind:class="imgCH " />              
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpeg$/);
      console.log(images);
      return images("./" + pet + ".jpeg");
    },
  },
  data() {
    const slides = [
      {bild:"panorama1",text:""},
      {bild:"panorama2",text:""},
      {bild:"panorama7",text:""},
      
    ];
    return {
      slides,
    };
  },
};
</script>
