<template>
  <div class="card text-center text-vue" style="
    width: 602px;"
>
    <div class="card-header">
      <h2 class="bg-vue2">Impressum</h2>
    </div>
    <div class="card-body">
      <p class="fw-bold">
        Perfektbau Bauträger GmbH
        </p>
        <p class="fw-bold">
        Saarstr. 6a  
        </p>
        <p class="fw-bold">
          08056 Zwickau  
        </p>
        <p>
          Mobil: 0176 21538288  
        </p>
        <p>
          info@perfektbau-weigelt.de
        </p>
        <p>
          
      </p>
      <button type="button" class="btn btn-danger" @click="close()">
        Close
      </button>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer"></div>
  </div>
</template>
<script>
  import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalImpressum",   
  methods:{
  close() {
      closeModal();
    },
    },
};
</script>
