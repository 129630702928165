<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0">
          <div class="row ">
            <div class="col-12 p-0">
              <img src="../assets/Logo.png" class="logoObenLinks" alt="..." />
              <img src="../assets/panorama2.jpeg" class="img-fluid" alt="..." />
              

            </div>
          </div>
          <div class="row justify-content-center nav">
            <div class="col-md-auto">
              <nav class="navbar navbar-expand-lg nav">
                <div class="container-fluid">
                  <ul class="navbar-nav me-auto  hemihead">
                    <li class="nav-item active">
                      <router-link 
                      to="/" 
                      class="nav-link nav "
                       v-bind:class="[activeScreen == '/' ? 'nav-active' : 'nav-deactiv']"
                        >Startseite</router-link >
                    </li>
                    <li class="nav-item dropdown ">
                      <router-link
                        to="/Winterdienst"
                        @mouseover="toggleDdown()"
                        @click="toggleDdown()"
                        class="nav-link nav-active"
                        >Winterdienst</router-link
                      >

                      <ul class="ddown-menu keinHandy" v-if="visi == true">                        
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Winterdienst"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Winterdienst</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>                        
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>                                 
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Entsorgung"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Entsorgung</router-link
                          >
                        </li>                      
                        <li class="ddownLi mb-4">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link "
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>
                      </ul>
                    </li>


                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>
                        <li class="ddownLi  handyOnly">
                          <router-link
                            to="/Entsorgung"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Entsorgung</router-link
                          >
                        </li>
                        <li class="ddownLi handyOnly">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>

                    <li class="nav-item nav">
                      <router-link to="/Auftragsformular" class="nav-link nav"
                       v-bind:class="[activeScreen == '/Auftragsformular' ? 'nav-active' : 'nav-deactiv']"
                        >Auftragsformular</router-link
                      > 
                    </li>
                    <li class="nav-item nav">
                      <router-link to="/Kontakt" class="nav-link nav"
                       v-bind:class="[activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv']"
                        >Kontakt</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div class="row unten">
            <div class="col-md-3 ps-5 pt-3">
              <img src="../assets/winter1.jpeg" class="img-fluid " alt="..." />
            </div>
            <div class="col-md-3 ps-5 pt-3">
              <img src="../assets/winter2.jpeg" class="img-fluid" alt="..." />
            </div>
            <div class="col-md-6 ps-5 pt-3">
              <h3>Winterdienst</h3>
              <ul>
                <li>Zuverlässige Sicherung von Wegen und Flächen</li>
                <li>Schneeräumung und Glatteisbeseitigung maschinell oder manuell</li>
                <li>Streumittel nach Bedarf mit Sand, Splitt oder Salz</li>
                <li>Überwachung der gesamten Wintersaison</li>
                <li>Bereitschaft 24 Std./7 Tage auch an Sonn- und Feiertagen</li>
                <li>Ständige Wetterbeobachtung durch Wetterdienste zum effizienten Handeln</li>
                <li>Streugutentfernung am Ende der Saison</li>
                <li>Genaues Protokollieren im Winterdiensttagebuch</li>
              </ul>

            </div>
          </div>
          <div class="row ">
            <div class="col-md-2  footer offset-md-10">    
              <span @click="openImpressum()">Impressum</span> &nbsp;
              <span @click="openDatenschutz()">Datenschutz</span>              
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { mapGetters } from "vuex";
import modalImpressum from "../components/Impressum.vue";
import modalDatenschutz from "../components/Datenschutz.vue";
import { openModal } from "jenesius-vue-modal";


export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      visi: false,
    };
  },
  components: {
    Layout,
  },
  methods: {
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 5000);
    },
    openImpressum(){
     openModal(modalImpressum);
    },
    openDatenschutz(){
     openModal(modalDatenschutz);
    },

    ifAktuell() {
      if (this.activeScreen == "/") {
        return true;
      } else if (this.activeScreen == "/Winterdienst") {
        return true;
      } else if (this.activeScreen == "/Auftragsformular") {
        return true;
      } else if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
};
</script>

<style scoped></style>
