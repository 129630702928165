<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 p-0">
              <img src="../assets/Logo.png" class="logoObenLinks" alt="..." />
              <img src="../assets/panorama8.jpeg" class="img-fluid" alt="..." />
            </div>
          </div>
          <div class="row justify-content-center nav">
            <div class="col-md-auto">
              <nav class="navbar navbar-expand-lg nav">
                <div class="container-fluid">
                  <ul class="navbar-nav me-auto  hemihead">
                    <li class="nav-item active">
                      <router-link
                        to="/"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/' ? 'nav-active' : 'nav-deactiv',
                        ]"
                        >Startseite</router-link
                      >
                    </li>
                    <li class="nav-item dropdown">
                      <router-link
                        to="/Winterdienst"
                        @mouseover="toggleDdown()"
                        @click="toggleDdown()"
                        class="nav-link"
                        v-bind:class="[
                          activeScreen == '/Winterdienst'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Leistungen</router-link
                      >

                      <ul class="ddown-menu keinHandy" v-if="visi == true" >
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Winterdienst"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Winterdienst</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Entsorgung"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Entsorgung</router-link
                          >
                        </li>
                        <li class="ddownLi mb-4">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>
                      </ul>
                       
                    </li>
                    <li class="nav-item nav">
                      <router-link
                        to="/Auftragsformular"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/Auftragsformular'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Auftragsformular</router-link
                      >
                    </li>
                    <li class="nav-item nav">
                      <router-link
                        to="/Kontakt"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/Kontakt'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Kontakt</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>

          <div class="contact_form">
            <div class="row unten">
              <div class="col-md-3 ps-5 pt-3">
                <img src="../assets/kontakt1.jpg" class="img-fluid" alt="..." />
              </div>
              <div class="col-md-9 ps-5 pt-3">
                <div class="row">
                  <div class="col-md-4">
                    <h3>Kontaktformular</h3>
                  </div>
                  <div class="col-md-6">
                    <span class="required_notification"
                      >* notwendige Eingaben</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <ul>
                      <li>
                        <label for="name">Name:</label>
                        <input
                          type="text"
                          name="name"
                          v-bind:class="background.name"
                          placeholder="Mein Name"
                          v-model="mail.name"
                          required
                        />
                      </li>
                      <li>
                        <label for="email">Email:</label>
                        <input
                          type="text"
                          name="email"
                          placeholder="max@example.de"
                          v-model="mail.email"
                          v-bind:class="background.email"
                          required
                        />
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="datenschutz"
                          class="checkbox"
                          v-model="mail.datenschutz"
                          v-bind:class="background.datenschutz"
                          required
                        /><span
                          class="ps-1 checkboxLabel"
                          v-bind:class="background.datenschutz"
                          for="email"
                          > Die Angaben zum
                          <span class="underline" @click="openDatenschutz()"
                            >Datenschutz</span
                          >
                          habe ich gelesen .
                        </span>
                      </li>
                      <li>
                        <button
                          class="submit"
                          type="submit"
                          @click="sendMail()"
                        >
                          Absenden
                        </button>
                      </li>
                      <li>                        
                    {{mail.gesendet}}
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-7">
                    <label class="align-top" for="message">Nachricht:</label>
                    <textarea
                      name="message"
                      cols="160"
                      rows="5"
                      v-model="mail.message"
                      v-bind:class="background.message"
                      required
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <div class="row">
           <div class="col-md-2 offset-md-10 col-s-12 offset-s-0 footer">
            <span @click="openImpressum()">Impressum</span> &nbsp;
            <span @click="openDatenschutz()">Datenschutz</span>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { mapGetters } from "vuex";

import modalImpressum from "../components/Impressum.vue";
import modalDatenschutz from "../components/Datenschutz.vue";
import { openModal } from "jenesius-vue-modal";
import axios from "axios";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      visi: false,
      mail: {
        name: "",
        email: "",
        message: "",
        datenschutz: false,
        gesendet:"",
      },
      background: {
        name: "",
        email: "",
        message: "",
        datenschutz: "",
      },
    };
  },
  components: {
    Layout,
  },

  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "info@perfektbau-weigelt.de",
          name: this.mail.name,
          email: this.mail.email,
          text: this.mail.message,
          datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://kito-design.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet="Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name="";
             background.name="";
            mail.email="";
             background.email="";
            mail.message="";
             background.message="";
            mail.datenschutz=false;
             background.datenschutz="";
          })
          .catch((error) => {
            console.log(error.response);
             mail.gesendet="Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 5000);
    },
    openImpressum() {
      openModal(modalImpressum);
    },
    openDatenschutz() {
      openModal(modalDatenschutz);
    },

    ifAktuell() {
      if (this.activeScreen == "/") {
        return true;
      } else if (this.activeScreen == "/Winterdienst") {
        return true;
      } else if (this.activeScreen == "/Auftragsformular") {
        return true;
      } else if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
};
</script>
<style scoped>
*:focus {
  outline: none;
}

.contact_form h2,
.contact_form label {
   font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;

}

.form_hint,
.required_notification {
  font-size: 11px;
}
.contact_form ul {
  list-style-type: none;
  list-style-position: outside;
  margin: 0px;
  padding: 0px;
}
.contact_form li {
  padding: 12px;
  border-bottom: 1px solid #eee;
  position: relative;
}
.contact_form li:first-child,
.contact_form li:last-child {
  border-bottom: 1px solid #777;
}
.contact_form h2 {
  margin: 0;
  display: inline;
}
.required_notification {
  color: #d45252;
  margin: 5px 0 0 0;
  display: inline;
  float: right;
}
.contact_form label {
  width: 150px;
  margin-top: 3px;
  display: inline-block;
  padding: 3px;
}
.contact_form input {
  height: 20px;
  width: 220px;
  padding: 5px 8px;
}
.checkbox {
  width: 20px !important;
}
.checkboxLabel {
  width: 355px !important;
}
.underline {
  text-decoration: underline;
}
.contact_form textarea {
  padding: 8px;
  width: 450px;
}
.contact_form button {
  margin-left: 156px;
}
.contact_form input,
.contact_form textarea {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  border-radius: 2px;
}
.contact_form input:focus,
.contact_form textarea:focus {
  background: #fff;
  border: 1px solid #555;
  box-shadow: 0 0 3px #aaa;
}
/* Button Style */
button.submit {
  background-color: #68b12f;
  background: -webkit-linear-gradient(top, #68b12f, #50911e);
  background: -moz-linear-gradient(top, #68b12f, #50911e);
  background: -ms-linear-gradient(top, #68b12f, #50911e);
  background: -o-linear-gradient(top, #68b12f, #50911e);
  background: linear-gradient(top, #68b12f, #50911e);
  border: 1px solid #509111;
  border-bottom: 1px solid #5b992b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: inset 0 1px 0 0 #9fd574;
  -webkit-box-shadow: 0 1px 0 0 #9fd574 inset;
  -moz-box-shadow: 0 1px 0 0 #9fd574 inset;
  -ms-box-shadow: 0 1px 0 0 #9fd574 inset;
  -o-box-shadow: 0 1px 0 0 #9fd574 inset;
  color: white;
  font-weight: bold;
  padding: 6px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 #396715;
}
button.submit:hover {
  opacity: 0.85;
  cursor: pointer;
}
button.submit:active {
  border: 1px solid #20911e;
  box-shadow: 0 0 10px 5px #356b0b inset;
  -webkit-box-shadow: 0 0 10px 5px #356b0b inset;
  -moz-box-shadow: 0 0 10px 5px #356b0b inset;
  -ms-box-shadow: 0 0 10px 5px #356b0b inset;
  -o-box-shadow: 0 0 10px 5px #356b0b inset;
}
.contact_form input:focus,
.contact_form textarea:focus {
  /* add this to the already existing style */
  padding-right: 70px;
}
.contact_form input,
.contact_form textarea {
  /* add this to the already existing style */
  -moz-transition: padding 0.25s;
  -webkit-transition: padding 0.25s;
  -o-transition: padding 0.25s;
  transition: padding 0.25s;
}
.contact_form input,
.contact_form textarea {
  padding-right: 30px;
}
input:required,
textarea:required {
  background: #fff no-repeat 98% center;
}
::-webkit-validation-bubble-message {
  padding: 1em;
}
.contact_form input:focus:invalid,
.contact_form textarea:focus:invalid {
  /* when a field is considered invalid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #d45252;
  border-color: #b03535;
}
.contact_form input:required:valid,
.contact_form textarea:required:valid {
  /* when a field is considered valid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #5cd053;
  border-color: #28921f;
}
.form_hint {
  background: #d45252;
  border-radius: 3px 3px 3px 3px;
  color: white;
  margin-left: 8px;
  padding: 1px 6px;
  z-index: 999; /* hints stay above all other elements */
  position: absolute; /* allows proper formatting if hint is two lines */
  display: none;
}
.form_hint::before {
  content: "\25C0"; /* left point triangle in escaped unicode */
  color: #d45252;
  position: absolute;
  top: 1px;
  left: -6px;
}
.contact_form input:focus + .form_hint {
  display: inline;
}
.contact_form input:required:valid + .form_hint {
  background: #28921f;
} /* change form hint color when valid */
.contact_form input:required:valid + .form_hint::before {
  color: #28921f;
} /* change form hint arrow color when valid */
</style>
