<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0">
          <div class="row">
            <div class="col-12 p-0">
              <img
                src="../assets/Logo.png"
                class="logoObenLinks rounded-3"
                alt="..."
              />
              <img src="../assets/panorama9.jpeg" class="img-fluid" alt="..." />
            </div>
          </div>
          <div class="row justify-content-center nav">
            <div class="col-md-auto">
              <nav class="navbar navbar-expand-lg nav">
                <div class="container-fluid">
                  <ul class="navbar-nav me-auto hemihead">
                    <li class="nav-item active hemihead">
                      <router-link
                        to="/"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/' ? 'nav-active' : 'nav-deactiv',
                        ]"
                        >Startseite</router-link
                      >
                    </li>
                    <li class="nav-item dropdown">
                      <router-link
                        to="/Winterdienst"
                        @mouseover="toggleDdown()"
                        @click="toggleDdown()"
                        class="nav-link"
                        v-bind:class="[
                          activeScreen == '/Winterdienst'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Leistungen</router-link
                      >

                      <ul class="ddown-menu keinHandy" v-if="visi == true">
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Winterdienst"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Winterdienst</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Gruenanlagen"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Grünanlagen</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Graupflege"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Graupflege</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Hausmeister"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Hausmeisterservice</router-link
                          >
                        </li>
                        <li class="ddownLi ddUnterstrich">
                          <router-link
                            to="/Entsorgung"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Entsorgung</router-link
                          >
                        </li>
                        <li class="ddownLi mb-4">
                          <router-link
                            to="/Sonderauftrag"
                            class="ddown-link"
                            v-bind:class="'nav-deactiv'"
                            >Sonderaufträge</router-link
                          >
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item nav">
                      <router-link
                        to="/Auftragsformular"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/Auftragsformular'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Auftragsformular</router-link
                      >
                    </li>
                    <li class="nav-item nav">
                      <router-link
                        to="/Kontakt"
                        class="nav-link nav"
                        v-bind:class="[
                          activeScreen == '/Kontakt'
                            ? 'nav-active'
                            : 'nav-deactiv',
                        ]"
                        >Kontakt</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div class="row">
            <div class="col-12 footer">
              <h3 class="ps-5">Auftragsformular</h3>
            </div>
          </div>
          <div class="contact_form">
            <div class="row unten">
              <div class="col-sm-4  col-xs-12 offset-xs-0 pt-0">
                <ul>
                  <li>
                    <label for="anrede">Anrede:</label>
                    <br/>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="anrede"
                        id="inlineRadio1"
                        value="Frau"
                        v-model="mail.anrede"
                        v-bind:class="background.anrede"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >Frau</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="anrede"
                        id="inlineRadio2"
                        value="Herr"
                        v-model="mail.anrede"
                        v-bind:class="background.anrede"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >Herr</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="anrede"
                        id="inlineRadio3"
                        value="Divers"
                        v-model="mail.anrede"
                        v-bind:class="background.anrede"
                      />
                      <label class="form-check-label" for="inlineRadio3"
                        >Diverse</label
                      >
                    </div>
                  </li>
                  <li>
                    <label for="firma">Firma:</label>
                    <input
                      type="text"
                      name="firma"
                      placeholder="Firma"
                      v-model="mail.firma"
                      v-bind:class="background.firma"
                    />
                  </li>
                  <li>
                    <label for="name">Name, Vorname : *</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Max Mustermann"
                      required
                      v-model="mail.name"
                      v-bind:class="background.name"
                    />
                  </li>
                  <li>
                    <label for="strasse">Straße, Nr : *</label>
                    <input
                      class="str"
                      type="text"
                      name="strasse"
                      placeholder="Straße"
                      required
                      v-model="mail.strasse"
                      v-bind:class="background.strasse"
                    />

                    <input
                      class="num"
                      type="text"
                      name="num"
                      placeholder="1"
                      required
                      v-model="mail.num"
                      v-bind:class="background.num"
                    />
                  </li>
                  <li>
                    <label for="plz">PLZ, Ort: *</label>
                    <input
                      class="plz"
                      type="number"
                      name="plz"
                      placeholder="12345"
                      required
                      v-model="mail.plz"
                      v-bind:class="background.plz"
                    />

                    <input
                      class="ort"
                      type="text"
                      name="ort"
                      placeholder="Großstadt"
                      required
                      v-model="mail.ort"
                      v-bind:class="background.ort"
                    />
                  </li>
                </ul>
              </div>
              <div class="col-sm-4  col-xs-12 offset-xs-0 pt-0">
                <ul>
                  <li>&nbsp;</li>
                  <li>
                    <label for="auswahl"> Leistung: *</label>
                    <select
                      class="form-select form-select-sm"
                      name="auswahl"
                      v-model="mail.auswahl"
                      v-bind:class="background.auswahl"
                    >
                      <option value="Gruenanlage">
                        Grünanlagen
                      </option>
                      <option value="Graupflege">Graupflege</option>
                      <option value="Hausmeister">Hausmeisterdienst</option>
                      <option value="Winterdienst">Winterdienst</option>
                      <option value="Sonstiges">Sonstiger Auftrag</option>
                    </select>
                  </li>
                  <li>
                    <label for="telefon">Telefon:</label>
                    <input
                      type="text"
                      name="telefon"
                      placeholder="0123 456 789"
                      v-model="mail.telefon"
                      v-bind:class="background.telefon"
                    />
                  </li>
                  <li>
                    <label for="email">Email: *</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="max@example.de"
                      v-model="mail.email"
                      v-bind:class="background.email"
                      required
                    />
                  </li>
                  <li>
                    <button class="submit" type="submit" @click="sendMail()">
                      Absenden
                    </button>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4  col-xs-12 offset-xs-0 pt-0">
                <ul>
                  <li>
                    <label class="align-top" for="message">Beschreibung:</label>
                    <textarea name="message" cols="50" rows="5" required>
                    </textarea>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="datenschutz"
                      class="form-check-input"
                      v-model="mail.datenschutz"
                      v-bind:class="background.datenschutz"
                      required
                    /><span  
                      class="datenschutzLabel"
                      
                    >
                      Die Angaben zum
                      <span class="underline" @click="openDatenschutz()"
                        >Datenschutz</span
                      >
                      habe ich gelesen. *
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 footer offset-md-10">
              <span @click="openImpressum()">Impressum</span> &nbsp;
              <span @click="openDatenschutz()">Datenschutz</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { mapGetters } from "vuex";
import modalImpressum from "../components/Impressum.vue";
import modalDatenschutz from "../components/Datenschutz.vue";
import { openModal } from "jenesius-vue-modal";
import axios from "axios";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      visi: false,

      mail: {
        anrede: "",
        name: "",
        firma: "",
        strasse: "",
        num: "",
        plz: "",
        ort: "",
        telefon: "",
        auswahl: "",
        email: "",
        message: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        anrede: "",
        name: "",
        firma: "",
        strasse: "",
        num: "",
        plz: "",
        ort: "",
        telefon: "",
        auswahl: "",
        email: "",
        message: "",
        datenschutz: "",
        gesendet: "",
      },
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }

      if (this.mail.strasse == "") {
        this.background.strasse = "border border-2 border-danger";
        re = false;
      } else {
        this.background.strasse = "border border-2 border-success";
      }

      if (this.mail.num == "") {
        this.background.num = "border border-2 border-danger";
        re = false;
      } else {
        this.background.num = "border border-2 border-success";
      }

      if (this.mail.plz == "") {
        this.background.plz = "border border-2 border-danger";
        re = false;
      } else {
        this.background.plz = "border border-2 border-success";
      }

      if (this.mail.ort == "") {
        this.background.ort = "border border-2 border-danger";
        re = false;
      } else {
        this.background.ort = "border border-2 border-success";
      }

      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }

      if (this.mail.auswahl == "") {
        this.background.auswahl = "border border-2 border-danger";
        re = false;
      } else {
        this.background.auswahl = "border border-2 border-success";
      }

      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "info@perfektbau-weigelt.de",
          Name: this.mail.name,
          Anrede: this.mail.anrede,
          Firma: this.mail.firma,
          Strasse: this.mail.strasse,
          Num: this.mail.num,
          PLZ: this.mail.plz,
          Ort: this.mail.ort,
          Telefon: this.mail.telefon,
          Email: this.mail.email,
          Auswahl: this.mail.auswahl,
          Text: this.mail.message,
          Datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://kito-design.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.anrede = "";
            background.anrede = "";
            mail.firma = "";
            background.firma = "";
            mail.strasse = "";
            background.strasse = "";
            mail.plz = "";
            background.plz = "";
            mail.ort = "";
            background.ort = "";
            mail.telefon = "";
            background.telefon = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },

    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 3000);
    },
    openImpressum() {
      openModal(modalImpressum);
    },
    openDatenschutz() {
      openModal(modalDatenschutz);
    },

    ifAktuell() {
      if (this.activeScreen == "/") {
        return true;
      } else if (this.activeScreen == "/Winterdienst") {
        return true;
      } else if (this.activeScreen == "/Auftragsformular") {
        return true;
      } else if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
};
</script>

<style scoped>
*:focus {
  outline: none;
}

.contact_form h2,
.contact_form label {
   font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;

}
.form_hint,
.required_notification {
  font-size: 11px;
}
.contact_form ul {
  list-style-type: none;
  list-style-position: outside;
  margin: 0px;
  padding: 0px;
}
.contact_form li {
  padding: 12px;
  position: relative;
}

.contact_form h2 {
  margin: 0;
  display: inline;
}
.required_notification {
  color: #d45252;
  margin: 5px 0 0 0;
  display: inline;
  float: right;
}
.contact_form label {
  width: 150px;
  margin-top: 3px;
  display: inline-block;
  padding: 3px;
}
.contact_form input {
  height: 20px;
  width: 300px;
  padding: 5px 8px;
}
.underline {
  text-decoration: underline;
}
.contact_form select {
  width: 300px;
  display: inline-block;
}
.plz {
  width: 80px !important;
  display: inline-block;
  margin-right: 20px;
  padding-right: 8px !important;
}
.ort {
  width: 200px !important;
  display: inline-block;
}
.str {
  width: 200px !important;
  display: inline-block;
  margin-right: 20px;
}
.num {
  width: 80px !important;
  display: inline-block;
  padding-right: 8px !important;
}
.form-check-input {
  width: 20px !important;
  padding: 1px !important;
}
.form-check-input:checked {
  background-color: rgb(44, 179, 73) !important;
}
.form-check-label {
  width: 35px !important;
  padding: 1px !important;
  margin-left: 4px;
  margin-right: 40px;
}
.datenschutzLabel {
  margin-left: 10px;
  width: 450px !important;
}
.form-check-inline {
  margin: 0ch;
}
.contact_form textarea {
  padding: 8px;
  width: 450px;
}
.contact_form button {
  margin-left: 156px;
}
.contact_form input,
.contact_form textarea {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  border-radius: 2px;
}
.contact_form input:focus,
.contact_form textarea:focus {
  background: #fff;
  border: 1px solid #555;
  box-shadow: 0 0 3px #aaa;
}
/* Button Style */
button.submit {
  background-color: #68b12f;
  background: -webkit-linear-gradient(top, #68b12f, #50911e);
  background: -moz-linear-gradient(top, #68b12f, #50911e);
  background: -ms-linear-gradient(top, #68b12f, #50911e);
  background: -o-linear-gradient(top, #68b12f, #50911e);
  background: linear-gradient(top, #68b12f, #50911e);
  border: 1px solid #509111;
  border-bottom: 1px solid #5b992b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: inset 0 1px 0 0 #9fd574;
  -webkit-box-shadow: 0 1px 0 0 #9fd574 inset;
  -moz-box-shadow: 0 1px 0 0 #9fd574 inset;
  -ms-box-shadow: 0 1px 0 0 #9fd574 inset;
  -o-box-shadow: 0 1px 0 0 #9fd574 inset;
  color: white;
  font-weight: bold;
  padding: 6px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 #396715;
}
button.submit:hover {
  opacity: 0.85;
  cursor: pointer;
}
button.submit:active {
  border: 1px solid #20911e;
  box-shadow: 0 0 10px 5px #356b0b inset;
  -webkit-box-shadow: 0 0 10px 5px #356b0b inset;
  -moz-box-shadow: 0 0 10px 5px #356b0b inset;
  -ms-box-shadow: 0 0 10px 5px #356b0b inset;
  -o-box-shadow: 0 0 10px 5px #356b0b inset;
}
.contact_form input:focus,
.contact_form textarea:focus {
  /* add this to the already existing style */
  padding-right: 70px;
}
.contact_form input,
.contact_form textarea {
  /* add this to the already existing style */
  -moz-transition: padding 0.25s;
  -webkit-transition: padding 0.25s;
  -o-transition: padding 0.25s;
  transition: padding 0.25s;
}
.contact_form input,
.contact_form textarea {
  padding-right: 30px;
}
input:required,
textarea:required {
  background: #fff no-repeat 98% center;
}
::-webkit-validation-bubble-message {
  padding: 1em;
}
.contact_form input:focus:invalid,
.contact_form textarea:focus:invalid {
  /* when a field is considered invalid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #d45252;
  border-color: #b03535;
}
.contact_form input:required:valid,
.contact_form textarea:required:valid {
  /* when a field is considered valid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #5cd053;
  border-color: #28921f;
}

.contact_form input:focus + .form_hint {
  display: inline;
}
.contact_form input:required:valid + .form_hint {
  background: #28921f;
} /* change form hint color when valid */
.contact_form input:required:valid + .form_hint::before {
  color: #28921f;
} /* change form hint arrow color when valid */
</style>
